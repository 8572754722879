<template>
  <b-row class="mb-5">
    <b-col v-if="isLoading == false" cols="12">
      <h2 class="text-dark fw-bold-700 mb-3 size24">
        Riwayat
      </h2>
      <app-timeline class="mb-2">
        <h6 v-if="histories.length == 0" class="text-primary">
          History belum ada
        </h6>
        <app-timeline-item v-for="(history, index) in histories" :key="index">
          <template>
            <div class="d-flex flex-sm-row flex-column flex-wrap mb-1 mb-sm-0">
              <h6 class="mb-0 size14" style="margin-right: 5px;" v-text="history.title" />
              <h6 class="timeline-item-time text-nowrap text-dark text-darken-5 fw-bold-500 size14"
                v-text="`oleh ${history.operator_name}`" />
            </div>
            <div v-for="(property, keyProperty, indexProperty) in history.properties" :key="indexProperty">
              <div v-if="keyProperty !== 'incoming_stock_items' && keyProperty !== 'incoming_stock_delivery_numbers'">
                <div v-for="(childProperty, keyChild, indexChild) in property" :key="indexChild">
                  <div class="d-flex align-items-center mb-1">
                    <h6 class="text-capitalize text-dark fw-bold-600 mb-0 size12">{{ keyChild.replaceAll('_', ' ') }}:
                    </h6>
                    <h4 class="text-dark fw-bold-600 mb-0 size14" style="margin-left: 5px;"
                      v-text="childProperty.from || '-'" />
                    <feather-icon v-if="childProperty.to || childProperty.from" size="18" class="text-dark fw-bold-600"
                      style="margin-left:5px;margin-right:5px;" icon="ArrowRightIcon" />
                    <h4 class="text-dark fw-bold-600 mb-0 size14" v-text="childProperty.to" />
                  </div>
                </div>
              </div>
            </div>
            <p class="mb-0" v-text="history.time" />
          </template>
        </app-timeline-item>
      </app-timeline>
      <a v-if="next_page_url" class="text-success ml-4 size14 fw-bold-700" style="color:#2C948A!important"
        @click="getData">
        Lihat Selengkapnya
      </a>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BRow,
    BCol,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    uuid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      histories: [],
      next_page_url: '',
      isLoading: false,
      page: 1,
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      this.isLoading = true
      await this.$http.get(`stocks/incoming/${this.$route.params.id || ''}/history`, {
        params: {
          // eslint-disable-next-line no-plusplus
          page: this.page++,
        },
      }).then(result => {
        // this.histories = result.data.data.data
        result.data.data.data.forEach(element => {
          this.histories.push(element)
        })
        this.next_page_url = result.data.data.next_page_url
        this.page = result.data.data
        this.isLoading = false
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
  },
}
</script>

<style lang="scss">
// Timeline
.timeline-variant-success {
  .timeline-item-point {
    background-color: unset !important;

    &::before {
      background-color: unset !important;
      background: linear-gradient(180deg, #45B6AB 0%, rgba(69, 182, 171, 0.5) 100%);
      box-shadow: inset 1.67273px 1.67273px 3.34545px rgb(230 219 211 / 20%);
    }
  }
}
</style>
