<template>
  <div class="bg-white">
    <header class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2">
      <b-row>
        <b-col cols="3" class="d-flex align-items-center">
          <div style="cursor: pointer" @click="$router.go(-1)">
            <feather-icon size="24" class="text-dark font-weight-bolder" icon="ArrowLeftIcon" />
            <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1">Kembali</span>
          </div>
        </b-col>
        <b-col cols="6" class="d-flex justify-content-center align-items-center">
          <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">
            Detail Produk Masuk
          </h1>
        </b-col>
        <b-col cols="3" class="text-right">
          <b-button v-if="
            $route.query.q !== 'warehouse'
              ? checkPermission('update stok supplier')
              : checkPermission('update stok gudang')
          " id="button--simpan" class="px-3" :disabled="isLoading || resultDetail.deleted_at !== null"
            @click.prevent="submit">
            <b-spinner v-if="isLoading" small />
            Simpan
          </b-button>
        </b-col>
      </b-row>
    </header>
    <b-container class="mt-5 pt-5">
      <b-row class="mx-0 mt-5 justify-content-center">
        <b-col lg="6">
          <h1 class="mb-3 text-dark fw-bold-600">Detail Produk Masuk</h1>
          <FormAddIncomingProduct :utang="utang" :is-edit="isEdit" v-if="$route.query.q == 'warehouse'" />
          <FormAddIncomingProductSupplier :utang="utang" :is-edit="isEdit" v-else />
        </b-col>
      </b-row>
      <b-row class="mx-0 mt-2 justify-content-center">
        <b-col lg="6">
          <h1 class="mb-3 text-dark fw-bold-600">Produk</h1>
        </b-col>
        <b-col cols="12">
          <FormPurchaseOrder :is-edit="isEdit" :warehouse-list="warehouseList"
            :nominal-discount-edit="nominalDiscountEdit" v-if="$route.query.q == 'warehouse'" />
          <FormPurchaseOrderSupplier :is-edit="isEdit" :warehouse-list="warehouseList"
            :nominal-discount-edit="nominalDiscountEdit" v-else />
        </b-col>
      </b-row>
      <b-row class="mx-0 mt-2 justify-content-center">
        <b-col lg="6">
          <h1 class="mb-2 text-dark fw-bold-600">Informasi Pembayaran</h1>
          <div class="mb-2">
            <h6 class="text-dark">Metode Pembayaran</h6>
            <h6 class="text-dark fw-bold-600 text-darken-4 fw-bold-600 mb-0 text-capitalize">
              {{ paymentMethods(resultDetail.payment_method) }}
            </h6>
          </div>
          <div class="">
            <h6 class="text-dark" v-if="resultDetail.payment_type !== 'tunai'">
              Tempo Pembayaran
            </h6>
            <h6 class="text-dark text-darken-4 fw-bold-600 mb-0">
              {{ resultDetail.tempo_due_date }}
            </h6>
          </div>
        </b-col>
      </b-row>
      <b-row class="mx-0 mt-2 justify-content-center">
        <b-col lg="6">
          <h1 class="mb-3 text-dark fw-bold-600">Biaya Lainnya</h1>
          <b-row>
            <b-col lg="6">
              <b-form-group
                class="custom__form--input"
                label="Biaya Tambahan"
                label-for="biaya-tambahan"
              >
                <money
                  id="biaya-tambahan"
                  v-model="formAdditional.additional_fee"
                  v-bind="money"
                  class="custom__input pl-1 w-100"
                  :disabled="
                    $route.query.q !== 'warehouse'
                      ? !checkPermission('update stok supplier') && isEdit
                      : !checkPermission('update stok gudang') && isEdit
                  "
                />
              </b-form-group>
            </b-col>
            <b-col lg="6">
              <b-form-group
                class="custom__form--input"
                label="Catatan"
                label-for="fee_note"
              >
                <b-form-input
                  id="fee_note"
                  v-model="formAdditional.additional_fee_note"
                  class="custom__input"
                  type="text"
                  :disabled="
                    $route.query.q !== 'warehouse'
                      ? !checkPermission('update stok supplier') && isEdit
                      : !checkPermission('update stok gudang') && isEdit
                  "
                />
              </b-form-group>
            </b-col>
            <b-col lg="12">
              <b-form-group
                class="custom__form--input"
                label="Catatan Penting"
                label-for="fee_note_important"
              >
                <b-form-input
                  id="fee_note_important"
                  v-model="formAdditional.note"
                  class="custom__input"
                  type="text"
                  :disabled="
                    $route.query.q !== 'warehouse'
                      ? !checkPermission('update stok supplier') && isEdit
                      : !checkPermission('update stok gudang') && isEdit
                  "
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mx-0 mt-2 justify-content-center">
        <b-col lg="6">
          <Riwayat :uuid="$route.params.id" v-if="$route.params.id" />
        </b-col>
      </b-row>
      <AddProduct v-if="$route.name == 'stock.gudang.detail'" @selectedProduct="getSelectedProduct" />
    </b-container>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BContainer,
  BFormInput,
  BFormGroup,
  BSpinner,
} from "bootstrap-vue";
import {
  checkPermission,
  successNotification,
  errorNotification,
  preformatFloat,
  formatRupiah,
} from "@/auth/utils";
import FormAddIncomingProduct from "@/components/Stock/FormWizard/FormAddIncomingProduct.vue";
import FormPurchaseOrder from "@/components/Stock/FormWizard/FormPurchaseOrder.vue";
import FormAddIncomingProductSupplier from "@/components/Stock/FormWizard/FormAddIncomingProductSupplier.vue";
import FormPurchaseOrderSupplier from "@/components/Stock/FormWizard/FormPurchaseOrderSupplier.vue";

import AddProduct from "@/components/Stock/Modal/AddProduct.vue";
import Riwayat from "@/components/Stock/Riwayat.vue";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BContainer,
    FormAddIncomingProduct,
    FormPurchaseOrder,
    FormAddIncomingProductSupplier,
    FormPurchaseOrderSupplier,
    BFormInput,
    BFormGroup,
    BSpinner,
    AddProduct,
    Riwayat,
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
      preformatFloat,
      formatRupiah,
    };
  },
  data() {
    return {
      indexProduct: 0,
      money: {
        decimal: "",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false,
      },
      isLoading: false,
      formAdditional: {
        additional_fee: "",
        additional_fee_note: "",
        note: "",
      },
      formProductsEdit: {
        items: [
          {
            product_uuid: "",
            qty: "",
            price: "",
            include_ppn: 0,
            include_fee_in_avg_price: 0,
            discounts: [],
            additional_fees: [],
            is_bonus: 0,
            warehouse_uuid: "",
          },
        ],
        per_surat_jalan_type: "fixed",
        per_surat_jalan_discount: 0,
        additional_fee: 0,
        additional_fee_note: "",
        note: "",
      },
      formPayload: {},
      formSupplier: this.$store.state.stockWarehouse.formSupplier,
      formProducts: this.$store.state.stockWarehouse.formProducts,
      payment: {},
      resultDetail: {},
      utang: {},
      resultProductItems: {},
      isEdit: true,
      warehouseList: [],
      nominalDiscountEdit: 0,
    };
  },
  watch: {
    "$store.state.stockWarehouse.formSupplier": {
      handler(value) {
        this.formSupplier = value;
      },
      deep: true,
    },
    "$store.state.stockWarehouse.formProducts": {
      handler(value) {
        this.formProducts = value;
      },
      deep: true,
    },
    "$store.state.stockWarehouse.payment": {
      handler(value) {
        this.payment = value;
      },
      deep: true,
    },
  },
  created() {
    this.getDetail().then(() => {
      setTimeout(() => {
        for (let index = 0; index < this.formProducts.items.length; index++) {
          const element = this.formProducts.items[index];
          document.getElementById(`rupiah-${index}`).value = this.formatRupiah(
            document
              .getElementById(`rupiah-${index}`)
              .value.toString()
              .replace(".", ","),
            "Rp. "
          );
          document.getElementById(`qty-${index}`).value = this.formatRupiah(
            document
              .getElementById(`qty-${index}`)
              .value.toString()
              .replace(".", ","),
            "Rp. "
          );
          this.formProducts.items[index].price = document.getElementById(
            `rupiah-${index}`
          ).value;
          this.formProducts.items[index].qty = document.getElementById(
            `qty-${index}`
          ).value;
        }

        document.getElementById("v-nominal-diskon").value = this.formatRupiah(
          document
            .getElementById("v-nominal-diskon")
            .value.toString()
            .replace(".", ","),
          "Rp. "
        );
        this.nominalDiscountEdit =
          document.getElementById("v-nominal-diskon").value;
      }, 1000);
    });
  },

  methods: {
    paymentMethods(method) {
      const paymentMapping = {
        cash: "Tunai",
        card: "Debit/Kredit",
        transfer: "Transfer",
        giro: "Giro",
        wallet: "Deposit",
        piutang: "Piutang",
      };

      return paymentMapping[method] || "-";
    },
    getSelectedProduct(product) {
      this.indexProduct = localStorage.getItem("indexProduct");
      const { formProductsEdit, indexProduct, $route, $store, resultDetail } =
        this;
      const { items } = formProductsEdit;
      const { q } = $route.query;

      items[indexProduct].product_uuid = {
        label: product.uuid,
        title: product.alias_name,
      };

      const latest_incoming_stock_supplier_price =
        product.latest_incoming_stock_supplier_price?.toString() || "0";
      const latest_incoming_stock_warehouse_price =
        product.latest_incoming_stock_warehouse_price?.toString() || "0";

      items[indexProduct].price =
        q === "warehouse"
          ? formatRupiah(latest_incoming_stock_warehouse_price, "")
          : formatRupiah(latest_incoming_stock_supplier_price, "");

      $store.commit("stockWarehouse/setFormProducts", {
        items,
        additional_fee: resultDetail.additional_fee,
        additional_fee_note: resultDetail.additional_fee_note,
        note: resultDetail.note,
      });
    },
    async getDetail() {
      try {
        const { $store, $route } = this;
        const getDataAction =
          $route.query.q === "warehouse"
            ? "stockWarehouse/getData"
            : "stockWarehouse/getStockSupplier";
        const result = await $store.dispatch(getDataAction, {
          uuid: $route.params.id,
          params: "",
        });
        const res = result.data.data;

        this.resultDetail = res;
        this.utang = res.utang;

        this.warehouseList = this.resultDetail.items;
        this.nominalDiscountEdit = this.resultDetail.per_surat_jalan_discount;
        console.log(res.items, 'res.items')
        const formProductsEditItems = res.items.map((el) => ({
          uuid: el.uuid,
          product_uuid: {
            title: el.product.name,
            label: el.product.uuid,
          },
          qty: el.qty,
          price: el.price,
          include_ppn: el.include_ppn ? 1 : 0,
          include_fee_in_avg_price: el.include_fee_in_avg_price ? 1 : 0,
          discounts: el.discounts || [],
          additional_fees: el.additional_fees || [],
          is_bonus: el.is_bonus ? 1 : 0,
          warehouse_uuid: el?.warehouse?.uuid,
        }));

        this.formProductsEdit = {
          items: formProductsEditItems,
          per_surat_jalan_type: res.per_surat_jalan_type,
          per_surat_jalan_discount: res.per_surat_jalan_discount,
          additional_fee: res.additional_fee,
          additional_fee_note: res.additional_fee_note,
          note: res.note,
        };

        // for (let index = 0; index < formProductsEditItems.length; index++) {
        //   const element = formProductsEditItems[index];

        // }

        this.$store.commit("stockWarehouse/setFormProducts", {
          items: formProductsEditItems,
          per_surat_jalan_type: res.per_surat_jalan_type,
          per_surat_jalan_discount: res.per_surat_jalan_discount,
          additional_fee: res.additional_fee,
          additional_fee_note: res.additional_fee_note,
          note: res.note,
        });

        this.formAdditional = {
          additional_fee: res.additional_fee,
          additional_fee_note: res.additional_fee_note,
          note: res.note,
        };

        this.$store.commit("stockWarehouse/setPayment", {
          payment_type: res.payment_type,
          tempo_due_date: res.tempo_due_date,
          tunai_type: res.tunai_type,
          cash_total_payment: res.cash_total_payment,
          cash_change: res.cash_change,
          card_name: res.card_name,
          card_number: res.card_number,
          card_exp_date: res.card_exp_date,
          bank_name: res.bank_name,
          bank_account_name: res.bank_account_name,
          bank_account_number: res.bank_account_number,
          bank_transfer_attachment: res.bank_transfer_attachment,
          bank_account_payment_id: res.bank_account_payment_id,
          giro_account_name: res.giro_account_name,
          giro_account_number: res.giro_account_number,
          giro_bank: res.giro_bank,
        });

        this.$store.commit(
          "stockWarehouse/setPaymentMethod",
          res.payment_method
        );
        this.$store.commit("stockWarehouse/setDiscounts", res.discounts);
        this.$store.commit(
          "stockWarehouse/setAdditionalFees",
          res.additional_fees
        );

        if ($route.query.q === "warehouse") {
          this.setDataWarehouse(res);
        } else {
          this.setDataSupplier(res);
        }
      } catch (err) {
        console.log(err);
      }
    },
    setDataWarehouse(res) {
      this.$store.commit("stockWarehouse/setFormSupplier", {
        date: res.date,
        delivery_numbers: res.delivery_numbers,
        selectedSupplier: res.supplier,
        po_so_id: res.po_so_id,
        // warehouse_uuid: res.warehouse.uuid,
      })
    },
    setDataSupplier(res) {
      this.$store.commit("stockWarehouse/setFormSupplier", {
        date: res.date,
        shipping: res.shipping,
        supplier_uuid: res.supplier.uuid,
        po_so_id: res.po_so_id,
        delivery_numbers: res.delivery_numbers,
        // warehouse_uuid: res.warehouse.uuid,
      });
    },
    async submit() {
      // Use SweetAlert for confirmation
      this.$swal({
        title: "Konfirmasi",
        text: "Apakah Anda yakin ingin mengubah data stok gudang ini?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          try {
            this.isLoading = true;

            const paymentType = this.formPayload.payment_type;
            const isWarehouse = this.$store.state.stockWarehouse.isWarehouse;

            this.formPayload = {
              date: this.formSupplier.date,
              delivery_numbers: this.formSupplier.delivery_numbers,
              supplier_uuid: this.formSupplier.supplier_uuid,
              po_so_id: this.formSupplier.po_so_id,
              warehouse_uuid: this.formSupplier.warehouse_uuid,
              items: this.formProducts.items,
              per_surat_jalan_type: this.formProducts.per_surat_jalan_type,
              per_surat_jalan_discount:
                this.formProducts.per_surat_jalan_discount,
              additional_fee: this.formAdditional.additional_fee,
              additional_fee_note: this.formAdditional.additional_fee_note,
              note: this.formAdditional.note,
              payment_type: paymentType,
              tempo_due_date: this.payment.tempo_due_date,
              tunai_type: this.payment.tunai_type,
              payment_method: this.$store.state.stockWarehouse.payment_method,
              ...this.$store.state.stockWarehouse.payment,
            };

            delete this.formPayload.cash_total_payment;

            if (paymentType === "tunai") {
              delete this.formPayload.tempo_due_date;
            }

            if (paymentType === "tempo") {
              delete this.formPayload.tunai_type;
            }

            if (isWarehouse) {
              delete this.formPayload.shipping;
            } else {
              delete this.formPayload.delivery_number_note;
            }

            const form = this.preparePayload();

            const postDataAction =
              this.$route.query.q === "warehouse"
                ? "stockWarehouse/postData"
                : "stockWarehouse/postStockSupplier";

            await this.$store.dispatch(postDataAction, {
              uuid: this.$route.params.id,
              payload: form,
            });

            this.isLoading = false;
            successNotification(
              this,
              "Success",
              this.$route.query.q === "warehouse"
                ? "Stok lokasi SKU berhasil diubah"
                : "Stok supplier berhasil diubah"
            );
            this.$bvModal.hide("modal-add-product-in");
            this.$router.go(-1);
          } catch (err) {
            this.isLoading = false;
            console.log(err);
            if (err.response.data.meta.messages) {
              errorNotification(this, "Oops!", err.response.data.meta.messages);
            }
          }
        }
      });
    },
    preparePayload() {
      const form = new FormData();
      let cashTotalPayment = 0;

      for (const key in this.formPayload) {
        if (
          this.formPayload.hasOwnProperty(key) &&
          this.formPayload[key] != null &&
          key !== "items" &&
          key !== "delivery_numbers"
        ) {
          form.append(key, this.formPayload[key]);
        }
      }

      for (let i = 0; i < this.formPayload.items.length; i++) {
        const item = this.formPayload.items[i];

        for (const key in item) {
          if (
            item.hasOwnProperty(key) &&
            key !== "disc_percents" &&
            key !== "product_uuid" &&
            key !== "price" &&
            key !== "qty"
          ) {
            form.append(`items[${i}][${key}]`, item[key]);
          }

          if (key === "product_uuid") {
            form.append(`items[${i}][${key}]`, item[key].label || item[key]);
          }

          if (key === "price" || key === "qty") {
            const parsedValue = parseFloat(this.preformatFloat(item[key]));
            form.append(`items[${i}][${key}]`, parsedValue);
            cashTotalPayment =
              parseFloat(this.preformatFloat(item.price)) *
              parseFloat(this.preformatFloat(item.qty));
          }
        }

        for (let j = 0; j < item.discounts.length; j++) {
          const discount = item.discounts[j];

          for (const key in discount) {
            if (discount.hasOwnProperty(key)) {
              form.append(
                `items[${i}][discounts][${j}][${key}]`,
                discount[key]
              );
            }
          }
        }

        for (let j = 0; j < item.additional_fees.length; j++) {
          const discount = item.additional_fees[j];

          for (const key in discount) {
            if (discount.hasOwnProperty(key)) {
              form.append(
                `items[${i}][additional_fees][${j}][${key}]`,
                discount[key]
              );
            }
          }
        }
      }

      if (this.formPayload.delivery_numbers) {
        for (let i = 0; i < this.formPayload.delivery_numbers.length; i++) {
          const deliveryNumber = this.formPayload.delivery_numbers[i];
          if (deliveryNumber.delivery_number_note) {
            form.append(`delivery_numbers[${i}][uuid]`, deliveryNumber.uuid);
            form.append(
              `delivery_numbers[${i}][delivery_number_note]`,
              deliveryNumber.delivery_number_note
            );
          }
        }
      }

      form.append("cash_total_payment", cashTotalPayment);

      return form;
    },
    directPage() {
      this.clearItem();
    },
  },
  destroyed() {
    this.$store.commit("stockWarehouse/setFormProducts", {
      items: [
          {
            product_uuid: "",
            qty: "",
            price: "",
            include_ppn: 0,
            include_fee_in_avg_price: 0,
            discounts: [],
            additional_fees: [],
            is_bonus: 0,
            warehouse_uuid: "",
          },
        ],
        per_surat_jalan_type: "fixed",
        per_surat_jalan_discount: 0,
        additional_fee: 0,
        additional_fee_note: "",
        note: "",
    });
  }
};
</script>

<style lang="scss">
// body {
//   background-color: #fff !important;
// }
@media screen and (min-height: 480px) and (max-height: 1024px) and (orientation: landscape) {
  .custom__pricelist--row {
    max-width: 12rem !important;
  }

  .custom__ppn--row {
    max-width: 5rem !important;
  }

  .custom__pricelist--col {
    max-width: 9.5rem !important;
    margin-right: 2rem !important;

    .custom__input {
      max-width: 9.5rem !important;
    }
  }

  .custom__ppn--col {
    max-width: 5rem !important;
  }
}
</style>
